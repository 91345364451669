import React from 'react';
import Slider from "react-slick";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Outlet } from 'react-router';
import 'bootstrap/dist/css/bootstrap.css';
import "slick-carousel/slick/slick.css"; 
import './style.css'; 
import global_img from './img/gl.webp';
import malay_img from './img/my.webp';
import thai_img from './img/th.webp';
import indo_img from './img/id.webp';
import ind_img from './img/in.webp';
import bang_img from './img/bd.webp';
import chi_img from './img/cn.webp';
import kh_img from './img/kh.webp';
import vn_img from './img/vn.webp';
import lic_tick from './img/license_tick.webp';
import atillephone  from './img/antillephone-verified.webp';
import xcm from './img/xcm-verified.webp';
import logo from './img/nova88.webp';
import joe from './img/JC.webp';
import amber from './img/amber.webp';
import nova88_footer from './img/nova88world-footer.webp';
import facebook from './img/fb.png';
import twitter from './img/twitterx.png';
import instagram from './img/ig.png';
import telegram from './img/tg.png';
import youtube from './img/yt.png'; 


import advantplay from './img/partners/ADVANTPLAY.png';
import astrotech from './img/partners/ASTROTECH.png';
import cq9 from './img/partners/CQ9.png';
import gameplay from './img/partners/GAMEPLAY.png';
import habanero from './img/partners/HABANERO.png';
import jili from './img/partners/JILI.png';
import joker from './img/partners/JOKER.png';
import mega888 from './img/partners/MEGA888.png';
import microgaming from './img/partners/MICROGAMING.png';
import netent from './img/partners/NETENT.png';
import pgsoft from './img/partners/PGSOFT.png';
import playtech from './img/partners/PLAYTECH.png';
import pragmatic from './img/partners/PRAGMATIC-PLAY.png';
import redtiger from './img/partners/REDTIGER.png';
import simpleplay from './img/partners/simpleplay.png';
import skywind from './img/partners/skywind-group.png';
import spadegaming from './img/partners/SPADEGAMING.png';
import yggdrasil from './img/partners/YGGDRASSIL.png';
 

const exampleVariant = {
  visible: { opacity: 0.6, scale: 1, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 }
}
		

class DefaultApp extends React.Component{
	render(){
		return(
			<BrowserRouter>
				<Routes>
					<Route  element={<WithoutNav />}>
						<Route path="/" index element={<App />} /> 
						<Route path="/404" element={<PageNotFound />} />
						<Route path="*" element={<Navigate to="/404" />} />
					</Route>
				</Routes>
			</BrowserRouter>
		);
	}
}

const WithoutNav = () =>{
	return <Outlet />
}

function PageNotFound() {
	const cons = {
				color: "#444", 
				margin: "0",
				font: "normal 14px/20px Arial, Helvetica, sans-serif",
				height:"100%",
				background: "#fff"
			};
	const cons_body = {
				height: "auto",
				"min-height": "100%"
			};
	const cons_div = {
				"text-align": "center",
				width: "800px",
				"margin-left": "-400px",
				position: "absolute",
				top: "30%", 
				left: "50%"
			};
	const h1 = {
				margin:"0", 
				"font-size": "150px",
				"line-height": "150px",
				"font-weight": "bold"
			};
	const h2 = {
				"margin-top": "20px",
				"font-size": "30px"
			};		
	
  	return (
    	 
				<div style={ cons }>
					<div style={ cons_body }>     
					<div style={ cons_div }>
						<h1 style={ h1 }>404</h1>
						<h2 style={ h2 }>Not Found</h2>
						<p>The resource requested could not be found on this server!</p>
					</div>
					</div>
				</div>
  	);
}


		
class App extends React.Component{
	constructor(props){
		super(props);
		
		this.state={
			country : [
				{'name' : "International", 'image' : global_img, url:"https://nova88101.net/en"},
				{'name' : "Malaysia", 'image' : malay_img, url:"https://nova88101.net/en"},
				{'name' : "Thailand", 'image' : thai_img, url:"https://nova88bkk.com"}, 
				{'name' : "Indonesia",'image' : indo_img, url:"https://www.nova88-indo811.info/id"}, 
				{'name' : "Vietnam",'image' : vn_img, url:"https://www.nova88101.net/vn/"}, 
				{'name' : "Cambodia",'image' : kh_img, url:"https://www.nova88101.net/kh"}, 
				{'name' : "India", 'image' : ind_img, url:"https://www.nova88101.net/hi"}, 
				{'name' : "Bangladesh", 'image' : bang_img, url:"https://www.nova88101.net/bd"}, 
				{'name' : "China",'image' : chi_img, url:"https://www.nova88101.net/cs" }
			],
			social: [
				{'name' : 'facebook', 'image': facebook, 'url':"https://www.facebook.com/nova88official", "account":"Nova88Official"},
				{'name' : 'twitter', 'image': twitter, 'url':"https://twitter.com/Nova88official", "account":"@Nova88official"},
				{'name' : 'instagram', 'image': instagram, 'url':"https://www.instagram.com/nova88_official/", "account":"nova88_official"},
				{'name' : 'telegram', 'image': telegram, 'url':"https://t.me/nova88official", "account":"nova88official"},
				{'name' : 'youtube', 'image': youtube, 'url':"https://www.youtube.com/@Nova88Official", "account":"@Nova88Official"}
			]
		}
		
	}
	
	render(){
	const list_country = this.state.country.map((country, i) => <li key={i}><a href={ country.url }><img src={country.image} alt={ country.name } width="110" height="110"/> <br /> { country.name }</a></li> );
	const social_list = this.state.social.map((social, i) => <a href={ social.url } target="_blank" rel="noreferrer"><div class={ social.name} key={i}><div class="soc-img"><img src={ social.image } alt={ social.name } width="60" height="60"/></div><label>{ social.name }</label><p>{ social.account }</p></div></a> );
		
		return (
			<div>
				<motion.div className="bg">
					<div className="container">
						<div className="nova88_logo">
							<img className="nv88_img" src={ logo } alt="Nova88" width="700" height="300"/><br /><br />
							<h1 className="byline"> Asia's most popular bookmaker, featuring the greatest slots and live casino games. </h1>
							<div className="location_logo"> <ul>{ list_country }</ul></div> 
						</div>
					</div>
				</motion.div>
				<div className="header" id="myHeader">
	
				</div>
				<div className="content">
					<div id="content1">
						<div className="content1">
							<div className="container "> 
								<div className="jc-an">  
									<div className="jc-img-left-bottom">
										 <img src={ joe } alt="Joe Cole" width="800" height="800"/>
									</div>  
									<div className="an-img-right-bottom">
										<img src={ amber } alt="Amber Na" width="600" height="600" />
									</div> 
								</div> 
								<BrandAmbassador />
								<BrandSpokesperson /> 
								<hr/>
							</div>
						</div>
					</div>
					<div id="content2">
						<div className="content2">
							<SimpleSlider />
						 </div>
					</div>
					<div id="content3">
						<div className="container">
						<div className="license"> 
								<h2>Gaming License<img src={ lic_tick } className="license_tick" alt="" width="30" height="30"/></h2>
						<center>		<img alt="" src={ atillephone } width="100" height="100" />
								<img alt=""  src={ xcm } width="100" height="100" />
							 </center>
						</div>
						</div>
					</div> 
					<div id="content4">   
						<div className="container"> 
							<div className="ourpartners">
								<b> Our Partners </b> {/* <b> See all </b> */}
							</div> 
							<PartnerSlider />
						</div>
					</div>
					
					<div id="content5"> 
						<div className="container well">
						<div className="content5"> 
							<label> Follow Us </label>
							<div className="social">
								 
								  { social_list }  
							</div>
							<h3>Download Nova88 APP </h3> 
									<p>Nova88 is an online sports betting platform that offers a wide range of betting options on various sports events. The Nova88 app is a convenient way to access the platform on your mobile device.</p>
									<p>Here are some potential reasons why you might consider downloading the Nova88 app: </p>
						  
								<DownloadAppReasons /> 
							<p className="reminder">However, it is important to remember that sports betting can be addictive and may lead to financial problems if not done responsibly. You should always gamble responsibly and only bet what you can afford to lose.</p>
							
						</div> 
						</div> 
					</div>	 
					
					<div id="footer">
						<div className="container">
							<img className="nova88_footer" src={ nova88_footer } width="1296" height="336" alt="n88banner" /> 
							<div className="copyrights">&copy;Copyright 2023. Nova88. All Rights Reserved. 18+</div>
						</div>
					</div>    
				</div>
			</div>
		);
	}
}


function BrandAmbassador(){
	const control = useAnimation();
	  const [ref, inView] = useInView(); 
	  useEffect(() => {
		if (inView) {
		  control.start("visible");
		} else {
		  control.start("hidden");
		}
	  }, [control, inView]);
  
	return (
			<motion.div 
      ref={ref}
      variants={exampleVariant}
      initial="hidden"
      animate={control} className="ambasador">
				<h2>Joe Cole</h2>
				<h3>Nova88 Ambassador</h3>
				<h4>2021 - 2023</h4>
				<p>Joe Cole is a retired English professional footballer who played as an attacking midfielder. He was born on November 8, 1981, in London, England. Cole began his professional football career at West Ham United, where he made his debut in 1998 at the age of 17. He played for the club for six seasons before moving to Chelsea in 2003.</p>
				<p>During his time at Chelsea, Cole won three Premier League titles, two FA Cups, and one League Cup. He also played for the England national team, earning 56 caps and scoring 10 goals. After leaving Chelsea in 2010, Cole went on to play for several other clubs, including Liverpool, Lille, Aston Villa, Coventry City, and Tampa Bay Rowdies. He retired from professional football in 2018. </p>
				<p>Since retiring, Cole has worked as a football pundit for various media outlets, including BT Sport, Sky Sports, and BBC Sport as well as becoming an Ambassador for Nova88 for 2 consecutive years. </p>
			</motion.div>
	);
}

function BrandSpokesperson(){
	const control = useAnimation();
	  const [ref, inView] = useInView();

	  useEffect(() => {
		if (inView) {
		  control.start("visible");
		} else {
		  control.start("hidden");
		}
	  }, [control, inView]);
	return ( 
			<motion.div 
      ref={ref}
      variants={exampleVariant}
      initial="hidden"
      animate={control} className="spokeperson">
				<h2>Amber Na</h2>
					<h3>Nova88 Spokeperson</h3>
					<h4>2021 - 2022</h4>
					<p>Amber Na (藍星蕾) is a Taiwanese DJ and music producer. She was born on October 7, 1992, in Taiwan, and began her career as a DJ in 2013. Amber Na is known for her high-energy performances and ability to mix a variety of music genres, including electronic dance music (EDM), hip-hop, and pop. She has performed at various music festivals and events in Taiwan, as well as in other countries such as Japan and China. </p>
					<p>In addition to her DJ career, Amber Na has also released several original tracks and remixes, including "Sweet Heart" and "Kiss Me." She is known for incorporating traditional Chinese elements into her music and performances, such as wearing a traditional Chinese dress during her sets.</p>
					<p>Amber Na has a large following on social media platforms such as Instagram and Facebook, where she shares updates on her music and performances with her fans. She is considered to be one of the rising stars of the Taiwanese music scene.</p>
			</motion.div>
	);
} 

function Slots(){ 
	return (
			<div className="slots"> 
				<h2>Slots Games</h2> 
				<div className="row">
					<div className="col-sm-3 col-3 col-md-3">
						<p> &emsp; &emsp; Slots games are popular in many parts of Asia, particularly in countries such as Malaysia, Thailand, Indonesia, Vietnam, India, Bangladesh, China, Japan, South Korea, and the Philippines. Nova88 offer a wide range of slots games, including traditional reel slots and video slots with various themes and features. </p>
						<p> &emsp; &emsp; One unique feature of slots games in Asia is the incorporation of cultural elements and symbols into the game design. For example, you may find slots games based on popular Asian folklore or featuring lucky numbers and animals that are significant in Asian culture.</p>
						<p> &emsp; &emsp; Additionally, there are some Asian-specific slots game providers such as Pragmatic Play, PG Soft, Spadegaming and CQ9 Gaming. These providers offer games with Asian-themed designs and unique features that cater to the Asian market. </p>
					</div>
					<div className="col-sm-9 col-9 col-md-9">
						<b>Slots games stand out from their competition in several ways: </b>
						<ul>
							<li><b>Wide variety of themes : </b> 
											Slot games come in many different themes, from popular movies and TV shows to classic fruit machines and exotic locations. This variety allows players to find a game that matches their interests and preferences. 
							</li>
							<li><b>Innovative features : </b>
									Many slot games offer innovative features, such as bonus rounds, free spins, cascading reels, and multipliers. These features can help players win more and keep the game exciting.  
							</li>
							<li><b>Easy to play : </b>
									Slots games are relatively easy to play, with simple rules and straightforward gameplay. This accessibility makes them popular among both casual and experienced players. 
							</li>
							<li><b>Attractive graphics and sound effects : </b>
									Slots games often feature high-quality graphics and sound effects, creating an immersive and engaging experience for players.
							</li>
							<li><b>Mobile compatibility : </b>
									Many slots games are now available on mobile devices, allowing players to enjoy them on the go. This convenience makes slots games even more accessible and popular. 
							</li>
						</ul>
				<b>People love slots games for various reasons, including: </b>
				<ul>
					<li><b>Easy to play : </b>
							As mentioned earlier, slots games are easy to play, with simple rules and straightforward gameplay. This accessibility makes them popular among both casual and experienced players. 
					</li>
					<li><b>Potential for big wins : </b>
							Slots games offer the potential for big wins, with some games featuring progressive jackpots that can reach millions of dollars. This excitement and the possibility of winning a life-changing amount of money is a significant draw for many players.
					</li>
					<li><b>Variety of themes : </b>
							Slots games come in many different themes, from classic fruit machines to popular movies and TV shows. This variety allows players to find a game that matches their interests and preferences.
					</li>
					<li><b>Entertainment value : </b>
							Slots games are entertaining and engaging, with attractive graphics and sound effects that create an immersive experience for players.
					</li>
					<li><b>Social aspect : </b>
							Slots games can be played alone or with others, making them a popular choice for both solo and social play.
					</li>
				</ul>
					</div> 
				
				</div>
			</div>
	)
}

function Casino(){
	return (
			<div className="casino">
				<h2>Live Casino</h2>
				<div className="row">
					<div className="col-sm-3 col-3 col-md-3">
						<p> &emsp; &emsp; Live casino games are very popular in South East Asia, particularly in countries such as Malaysia, Indonesia, and Thailand. These games are widely available at both land-based casinos and online casinos, and are enjoyed by players of all ages and backgrounds.</p>
						<p> &emsp; &emsp; The quality of live casino games in South East Asia can vary depending on the specific casino and the game provider they use. However, in general, live casino games in this region are known for their high-quality graphics, immersive gameplay, and a wide range of game options.</p>
						<p> &emsp; &emsp; One factor that can affect the quality of live casino games in South East Asia is the availability of reliable internet connectivity. In some areas, internet speeds may not be as fast or stable as in other regions, which can impact the overall gaming experience.</p>
						<p> &emsp; &emsp; Despite this, live casino games continue to grow in popularity in South East Asia, with many players enjoying the convenience and excitement of being able to play their favorite games from the comfort of their own homes or on their mobile devices.</p>
					
					</div>
					<div className="col-sm-9 col-9 col-md-9">
						<b>Live casinos stand out from their competition in several ways:</b>
						<ul className="casino-way">
						
							<li><b>Real-life experience: </b>Live casinos offer a real-life casino experience to players who cannot visit a physical casino. Players can interact with live dealers and other players, which creates a more social and engaging experience. </li>
							<li><b>Authenticity: </b>Unlike computer-generated games, live casinos use real dealers and real equipment, which adds authenticity to the gaming experience. Players can see the cards being dealt and the roulette wheel spinning, which increases their confidence in the fairness of the game.</li>
							<li><b>Wide game selection: </b> Live casinos offer a wide selection of games, including traditional table games like blackjack, roulette, baccarat, and poker, as well as game show-style games like Deal or No Deal, Monopoly Live, and Dream Catcher. </li>
							<li><b>Convenience: </b> Live casinos are available 24/7, so players can enjoy their favorite games at any time from their desktop or mobile device. This makes it more convenient for players who may not have access to a physical casino or prefer to play from the comfort of their own homes. </li>
							<li><b>Security: </b>Live casinos use advanced security protocols to ensure the safety and privacy of players' personal and financial information. This includes encryption technology to protect data, secure payment options, and regular audits to ensure fair play. </li>
						</ul>
						
						<b>Asian players are particularly drawn to live casino games for several reasons:</b>
						<ul className="casino-reasons">
							<li><b>Cultural familiarity: </b> Many traditional casino games, such as baccarat, originated in Asia and are deeply ingrained in the culture. This familiarity with the games makes them more appealing to Asian players. </li>
							<li><b>Superstitions: </b> Many Asian players believe in superstitions and lucky numbers, which they incorporate into their betting strategies. The social aspect of live casino games also allows them to interact with other players and dealers, which adds to the fun and excitement.</li>
							<li><b>Convenience: </b> Live casino games are available 24/7 and can be accessed from the comfort of a player's own home or on-the-go through a mobile device. This makes it more convenient for players who may not have access to a physical casino or prefer to play from home.</li>
							<li><b>Wide game selection: </b> Live casinos offer a wide selection of games, including traditional Asian games such as Sic Bo and Dragon Tiger. This provides players with a diverse range of options to choose from.</li>
							<li><b>High stakes: </b> Many Asian players are high rollers who enjoy placing large bets and taking risks. Live casino games provide the opportunity to play with higher stakes than computer-generated games, which adds to the thrill of the game.</li>
							<li>Overall, live casino games provide Asian players with a unique and exciting gaming experience that combines the cultural familiarity of traditional games with the convenience and excitement of online gaming.</li>
						</ul>
					
					</div> 
				</div>
			</div>
	)
}


function Sport(){
	return (
			<div className="game">
				<h2>Sportsbook</h2>
				<div className="row">
					<div className="col-sm-3 col-3 col-md-3">
						<p> &emsp; &emsp; Sports betting is very popular in South East Asia, and as a result, there are many sportsbooks operating in the region. The quality of sportsbooks can vary depending on the specific site, but in general, they offer a wide range of sports and betting options.</p>
						<p> &emsp; &emsp; Sportsbooks in South East Asia generally cover a variety of popular sports, including football, basketball, tennis, and cricket, as well as niche sports like badminton and esports. Many sportsbooks also offer live betting, allowing players to place bets while a game is in progress.</p>
						<p> &emsp; &emsp; In terms of odds and payouts, South East Asian sportsbooks can be competitive with those in other regions. Many sportsbooks offer competitive odds on popular sports, and some may even offer special promotions and bonuses to attract players.</p>
						<p> &emsp; &emsp; One factor that can impact the quality of sportsbooks in South East Asia is the regulatory environment. In some countries, sports betting may be heavily regulated or even illegal, which can limit the number of available sportsbooks and the types of bets that can be placed. </p>
					</div>
					<div className="col-sm-9 col-9 col-md-9">
						<b>Sports betting is a popular form of gambling in South East Asia, and it stands out in several ways:</b>
						<ul className="sports-way">
							<li><b>Cultural significance: </b> Sports, particularly football, are a significant part of the culture in many South East Asian countries. As a result, sports betting is seen as a natural extension of this cultural interest, and many people enjoy placing bets on their favorite teams and athletes.</li>
							<li><b>Wide variety of sports: </b> Sports betting in South East Asia covers a wide range of sports, including football, basketball, cricket, tennis, and many others. This provides players with a diverse range of options to choose from, catering to different interests and preferences.</li>
							<li><b>Competitive odds: </b> Sportsbooks in South East Asia often offer competitive odds on popular sports, which can attract players who are looking for good value for their bets.</li>
							<li><b>Mobile accessibility: </b> Many sportsbooks in South East Asia have mobile apps or mobile-optimized websites, which make it easy for players to place bets on-the-go, from anywhere with an internet connection.</li>
							<li><b>Social aspect: </b> Sports betting in South East Asia can be a social activity, with friends and family members often placing bets together and watching games together. This adds to the excitement and enjoyment of the experience.</li>
							<li><b>Regulatory environment: </b> The regulatory environment for sports betting in South East Asia varies depending on the country. In some countries, sports betting is legal and well-regulated, while in others it may be heavily restricted or even illegal. This can impact the quality and availability of sports betting options in the region.</li>
						</ul>
						
						<b>There are several reasons why people in South East Asia enjoy sports betting:</b>
						<ul className="sports-reason">
							<li><b>Access to technology: </b> With the rise of technology and the internet, sports betting has become more accessible to people in South East Asia. Many sportsbooks have mobile apps or mobile-optimized websites, which make it easy for people to place bets on-the-go, from anywhere with an internet connection.</li>
							<li><b>Potential for profit: </b> Sports betting offers the potential for significant profits, which can be attractive to people in South East Asia who may be looking for ways to supplement their income or make money.</li>
							<li><b>Entertainment value: </b> Sports betting can add an extra layer of excitement and entertainment to watching sports, as people have a vested interest in the outcome of the game. This can make watching sports more engaging and enjoyable.</li>
							<li>Overall, people in South East Asia enjoy sports betting for a variety of reasons, including the cultural significance of sports, the social aspect of betting with friends and family, the accessibility of technology, the potential for profit, and the entertainment value of betting on sports.</li>
						</ul>
					</div>
				</div>
			</div>
	)
}
 
 
class SimpleSlider extends React.Component {
  render() {
    const settings = {
      lazyload: 'ondemand', 
		autoplay: true, 
		infinite: true,
		autoplaySpeed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,    
		dots: true , 
		arrows: false,
		responsive:[{
			breakpoint: 767, 
				settings: "unslick"
		}]
    };
    return (
      <div className="container"> 
		<Slider {...settings}>
			<Slots />
			<Casino />
			<Sport />
		</Slider>
      </div>
    );
  }
}
 
 
class PartnerSlider extends React.Component {
	constructor(props){
		super(props);
		
		this.state  = {
			partners:[
					{ partner : 'MICROGAMING', 'image': microgaming },
					{ partner : 'NET-ENT', 'image': netent},
					{ partner : 'RED TIGER', 'image': redtiger},
					{ partner : 'ADVANTPLAY', 'image': advantplay},
					{ partner : 'HABANERO', 'image': habanero},
					{ partner : 'SKYWIND', 'image': skywind},
					{ partner : 'SIMPLE PLAY', 'image': simpleplay},
					{ partner : 'PRAGMATIC PLAY', 'image': pragmatic},
					{ partner : 'GAME PLAY', 'image': gameplay},
					{ partner : 'JOKER', 'image': joker},
					{ partner : 'YGGDRASIL', 'image': yggdrasil},
					{ partner : 'SPADEGAMING', 'image': spadegaming},
					{ partner : 'CQ9', 'image': cq9},
					{ partner : 'MEGA 888', 'image': mega888},
					{ partner : 'ASTROTECH', 'image': astrotech},
					{ partner : 'PLAYTECH', 'image': playtech},
					{ partner : 'JILI', 'image': jili},
					{ partner : 'PGSOFT', 'image': pgsoft}
				]
		}
	} 
	render() {
	const list_partner = this.state.partners.map((partner, i) => <div key={i} className="card">
																		<img src={ partner.image} alt={ partner.partner } width="130" height="55"/>
																		<div>{ partner.partner }</div>
																</div>);
	
    const settings = {
		lazyload: 'ondemand',
		autoplay: true,
		autoplaySpeed: 3000,
		infinite: true,
		slidesToShow: 7,
		slidesToScroll: 1, 
		swipeToSlide: true,
		arrows: false,
		dots: false,
		  responsive: [
			{
			  breakpoint: 1300,
			  settings: {
				slidesToShow: 6, 
				infinite: true 
			  }
			},
			{
			  breakpoint: 1020,
			  settings: {
				slidesToShow: 5, 
				infinite: true  
			  }
			},
			{
			  breakpoint: 950,
			  settings: {
				slidesToShow: 4, 
				infinite: true 
			  }
			},
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 3, 
				infinite: true 
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2, 
			  }
			}]
    };
    return (
		
			<div className="items">
				<Slider {...settings}>
					{ list_partner }
				</Slider>
			</div> 
    );
  }
} 

class DownloadAppReasons extends React.Component {
	constructor(props){
		super(props);
		
		this.state  = {
			reasons:[
					{ reason : 'Convenience', 'text': 'The Nova88 app allows you to access the platform easily and quickly from your mobile device, wherever you are.'},
					{ reason : 'User-friendly interface', 'text': 'The app has a user-friendly interface that is easy to navigate, even for beginners'},
					{ reason : 'Wide range of betting options', 'text': 'The platform offers a wide range of betting options on various sports events, including football, basketball, tennis, and more.'},
					{ reason : 'Live betting', 'text': 'You can place bets on live events in real-time using the Nova88 app, adding to the excitement of sports betting.'},
					{ reason : 'Promotions and bonuses', 'text': 'Nova88 offers various promotions and bonuses for users, which you can access through the app.'} 
				]
		}
	} 
	render() {
		const download_reasons = this.state.reasons.map((reason, i) => <div key={i} className="reason-container" >
																		<div className="reason-card card">
																			<div className="reason-head">{ reason.reason }</div>
																			<div className="reason-body">{ reason.text }</div>
																		</div>
																	</div>);
		const settings = {
			lazyload: 'ondemand',
			autoplay: true, 
			infinite: true,
			slidesToShow: 5,
			slidesToScroll: 1,   
			arrows: false,
			dots: false , 
			cssEase: 'linear',
			  responsive: [
				{
				  breakpoint: 1399,
				  settings: {
					slidesToShow: 4, 
					infinite: true 
				  }
				},
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3, 
					infinite: true 
				  }
				},
				{
				  breakpoint: 992,
				  settings: {
					slidesToShow: 2, 
					infinite: true 
				  }
				} ,
				{
				  breakpoint: 532,
				  settings: {
					slidesToShow: 1, 
					infinite: true 
				  }
				} 
				]
    };
    return (
      <div className="potential-reason">
		<Slider {...settings}>
			{ download_reasons }
		</Slider>
      </div>
    );
  }
}


export default DefaultApp;